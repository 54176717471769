
import api from '@/api/api'
import Constant from '@/constant/constant'
import messageBox from '@/constant/message'
import Cookies from 'js-cookie'
import {Decrypt} from '@/constant/crypto'
import {jumpToFromPath} from '@/util/common.js';
import {popupNotice} from '@/api/ware.js';


const user = {
	state: {
		userView: {},
		userPerm: {},
		bfUser:{},
		unionId: '',
		popup: {},
		wxLogin: {},
		loginModalVisible: false,
		registerType: 'register',
		// 根据用户选择，显示登陆 ｜ 注册
		hasUserCount: true,
		level:0,
		categoryClick:false,
	},

	mutations: {
		SET_CATEGORY_CLICK: (state, payload) => {
			state.categoryClick = payload
		},
		SET_CATEGORY_LEVEL: (state, payload) => {
			state.level = payload
		},
		SET_POPUP: (state, payload) => {
			state.popup = payload
		},
		SET_USER_VIEW: (state, payload) => {
			state.userView = payload
		},
		SET_USER_PERM: (state, payload) => {
			state.userPerm = payload || {};
		},
		SET_BF_USER: (state, payload) => {
			state.bfUser = payload || {};
		},
		SET_UNION_ID: (state, payload) => {
			state.unionId = payload
		},
		SET_WX_LOGIN: (state, payload) => {
			state.wxLogin = payload
		},
		SET_LOGIN_MODAL_VISIBLE: (state, {visible, type = 'register', path})=>{
			state.loginModalVisible = visible;
			state.registerType = type;
			if(path || typeof path === 'string') {
				window.sessionStorage.setItem('loginToPath', path);
			}
			
		},
		SET_REGISTER_TYPE: (state, payload)=>{
			state.registerType = payload;
		},
		HAS_USER_COUNT: (state, payload)=>{
			state.hasUserCount = payload;
		},
	},

	actions: {
		// 登录
		Login({dispatch, commit,state }, userInfo) {
			return new Promise((resolve, reject) => {
				api.UserLogin(userInfo).then(async res => {
					commit('SET_LOGIN_MODAL_VISIBLE', false);
					// 设置token
					localStorage.setItem(Constant.HttpAccessToken, res.accessToken)
					//为设计平台提供登录通讯
					Cookies.set(Constant.HttpAccessToken, res.accessToken,{domain: process.env.VUE_APP_DESIGN_DOMAIN,expires: 365})
					await dispatch('GetInfo');
					resolve()
				}).catch(error => {
					messageBox.error(error)
				})
			})
		},
		getBf({commit}) {
			return new Promise(async (resolve, reject) => {
				// 设置权限
				await api.bfUser().then((res) => {
					commit('SET_BF_USER',res)
				})
			})
		},
		// wc登录
		WxLogin({dispatch, commit, state }, userInfo) {
			return new Promise((resolve, reject) => {
				api.WechatCallback(userInfo).then(async res => {
				if(res.flag === Constant.wechatBind) {
					// 设置token
					localStorage.setItem(Constant.HttpAccessToken, res.accessToken)
					//为设计平台提供登录通讯
					Cookies.set(Constant.HttpAccessToken, res.accessToken,{domain: process.env.VUE_APP_DESIGN_DOMAIN,expires: 365})
					await dispatch('GetInfo')
					const path = window.sessionStorage.getItem('loginToPath');
					jumpToFromPath(path);
					resolve(res)
				}else {
					commit('SET_UNION_ID',res.unionId)
					resolve(res)
				}
				}).catch(err => {
					
				})
			})
		},
		// 获取用户信息
		GetInfo({dispatch, commit }) {
			return new Promise(async (resolve, reject) => {
				// 获取用户昵称
				await api.UserView().then((res) => {
					commit('SET_USER_VIEW',res)
				})
				// 设置权限
				await api.UserPerm().then((res) => {
					commit('SET_USER_PERM',res)
				})
				// 设置权限
				await api.bfUser().then((res) => {
					commit('SET_BF_USER',res)
				})
				// 设置权限
				await popupNotice().then((res) => {
					commit('SET_POPUP',res)
				})
				// await dispatch('loginCheck',1)
				
				api.UserLoginCheck({device:1,token:localStorage.getItem(Constant.HttpAccessToken)}).then(res => {
				}).catch(err => {
					if(err?.indexOf('1001') > -1) {
						setTimeout(function() {
							localStorage.clear()
							Cookies.remove(Constant.HttpAccessToken,{domain: process.env.VUE_APP_DESIGN_DOMAIN})
							if(timer) {
								clearInterval(timer)
							}
							window.location.href = '/'

						}, 3000)
					}else {
						reject(err)
					}
				})
				let timer = setInterval(() => {
					api.UserLoginCheck({device:1,token:localStorage.getItem(Constant.HttpAccessToken)}).then(res => {
					}).catch(err => {
						if(err?.indexOf('1001') > -1) {
							setTimeout(function() {
								localStorage.clear()
								Cookies.remove(Constant.HttpAccessToken,{domain: process.env.VUE_APP_DESIGN_DOMAIN})
								clearInterval(timer)
								window.location.href = '/'

							}, 3000)
						}else {
							clearInterval(timer)
							reject(err)
						}
					})
				}, 2*60*1000)
				resolve()
			})
		},
		loginCheck({},i) {

		},
		async getWeChatSetting({dispatch, commit }, userInfo) {
			return api.WechatSetting().then(res => {
				let obj = {
					appid:Decrypt(res.appid),
					state:res.state,
				}
				commit('SET_WX_LOGIN',obj)
			})
		}
	}
}

export default user