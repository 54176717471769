const buy = {
    state: {
        notice: false // 控制是否展示提醒框
    },

    mutations: {
        SET_NOTICE: (state, isShow) => {
            state.notice = isShow
        }
    },

    actions: {
        // 显示弹出提醒
        ShowNotice({commit}) {
            commit('SET_NOTICE', true)
        },
        // 隐藏弹出提醒
        HideNotice({commit}) {
            commit('SET_NOTICE', false)
        }
    }
}

export default buy