<template>
	<div class="home-header" :class="$route.path.includes('/f/help/')? 'new-header' : ''"
	:style="{background: (props.isShowNav !== undefined && !props.isShowNav) ? 'transparent' : 'rgba(29, 33, 41, 0.6)'}">
		<div class="logo" @click="router.push('/')">
			<img src="@/assets/images/logo2.png" alt="" />
		</div>
		<ul class="nav">
			<!-- <el-link 
				:class="$route.path == '/materials' && 'actived-nav'"
				:underline="false" 
				href="/materials" 
			>素材</el-link> -->
			<el-link 
				:class="$route.path === '/template' && 'actived-nav'"
				:underline="false" 
				href="/template"
			>模板中心</el-link>
			<el-link 
				:class="$route.path === '/study' && 'actived-nav'" 
				:underline="false" 
				href="/study" 
			>学习中心</el-link>
			<el-link
				:underline="false" 
				:href="AI_path" 
			>AI</el-link>
			<el-link 
				:class="$route.path === '/price' && 'actived-nav'" 
				:underline="false" 
				href="/price" 
			>定价</el-link>
			<!-- <el-link 
				:class="$route.path === '/design' && 'actived-nav'" 
				:underline="false" 
				href="/f/design" 
			>优秀设计</el-link> -->
			<el-link 
				:class="($route.path.includes('/f/help/'))  && 'actived-nav'" 
				:underline="false" 
				:href="`/f/help/${nodeId}`" 
			>帮助</el-link>
<!--			<el-link -->
<!--				:underline="false" -->
<!--				:href="missionPath" -->
<!--			>任务大厅</el-link>-->
		</ul>
		<div class="login-area">
			<el-button 
				v-show="!isLogin" 
				@click="handleLogin" 
				type="primary" 
				class="custom-button"
			>登陆 / 注册</el-button>
			<div v-show="isLogin">
				<el-button 
					@click="handleWorkSpace"
				>工作台</el-button>
				
				<el-button 
					class="custom-button" 
					type="primary" 
					@click="handleDesign"
					v-if="userStatus"
				>开始设计</el-button>
				<el-popover placement="bottom" :width="200" trigger="click" v-else>
					<div class="phoneBox">
					<div class="label">
						<el-icon><Phone /></el-icon>
					</div>
					<div class="value">
						0314-2539999
					</div>
					</div>
					<div class="imgBox">
                        <SellContract></SellContract>
					</div>
					<template #reference>
						<el-button 
							class="custom-button" 
							type="primary" 
						>开始设计</el-button>
					</template>
				</el-popover>
			</div>
		</div>
	</div>
</template>
	
<script setup>
import Constant from "@/constant/constant";
import {getTreeApi} from '@/api/login';
import { computed, defineProps, ref} from 'vue';
import {useStore} from "vuex";
import PersonPerm from '@/constant/personPerm';
import { useRoute, useRouter } from 'vue-router';
import SellContract from '@/components/common/sellContract'
const router = useRouter()
const nodeId = ref(0)
getTreeApi({bizCode:Constant.TreeArcticle,nodeId:'0',}).then((resp) => {
	nodeId.value = resp.result.children[0].nodeId
}).catch((err) => {
});
const {params} = useRoute()
const id = computed(() => params.id)

const store = useStore()
const props = defineProps(['isShowNav']);

const userStatus = computed(() => {
	return PersonPerm.design() || false;
})
const isLogin = computed(() => {
	return localStorage.getItem(Constant.HttpAccessToken) || false;
})

const missionPath = process.env.VUE_APP_MISSION_DOMAIN;
const AI_path = process.env.VUE_APP_AI_DOMAIN;


const handleLogin = async() => {
	
	await store.dispatch('getWeChatSetting');
	store.commit('SET_LOGIN_MODAL_VISIBLE', {
		visible: true,
		type: 'wxLogin',
		path: '/p',
	});
	
}


const handleDesign = () => {
	window.location.href = process.env.VUE_APP_DESIGN_URL;	
}
const handleWorkSpace = () => {
	router.push({ path: '/p'})
}

</script>
	
<style lang="scss" scoped>


.phoneBox {
	font-size: 14px;
	font-weight: 700;
}
.imgBox {
	margin-top: 10px;
	img {
		width: 180px;
	}
}
.label {
	display: inline-block;
}
.value {
	display: inline-block;
	padding-left: 10px;
}
.home-header {
	display: flex;
	padding: 20px 20px;
	align-items: center;
	z-index: 10;
	width: 100%;
	background: rgba(29, 33, 41, 0.6);
	position: fixed;
	top: 0;
	.logo {
		cursor: pointer;
	}
	.nav {
		margin: 0;
		display: flex;
		align-items: center;
		margin-left: 35px;
		padding: 0;
		:deep(.el-link) {
			color: #fff;
			list-style: none;
			margin-right: 30px;
			font-size: 16px;
		}
		:deep(.el-link .actived-nav) {
			color: #cf3861;
			font-size: 16px;
		}
		.actived-nav {
			color: #cf3861;
			font-weight: 800;
		}
		>li {
			
			color: #fff;
			list-style: none;
			margin-right: 30px;
			cursor: pointer;
		}
	}
	.login-area {
		margin-left: auto;
		margin-right: 40px;
		color: #fff;
		cursor: pointer;
	}
}
.new-header {
	display: block;
	position: absolute;
}
.custom-button {
	background-color: #cf3861;
	border: #cf3861;
	&:hover {
		background-color: #cf3861;
		border: #cf3861;
	}
}
</style>