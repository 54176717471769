<template>
    <div v-if="props.popDialog && textList.type !== Constant.Empty">
        <div class="maskBox"></div>
        <div class="dialogWrap" v-if="textList.type === Constant.TiXing">
            <img src="@/assets/popUp/close.png" alt="" class="closeBtn" @click="close">
            <div class="tui-dialog" style="width: 667px; height: 345px;">
                <div class="tui-dialog-header">
                </div>
                <div class="tui-dialog-body">
                    <div>
                        <div class="name">
                            {{ name }}(ID：{{ userNo }}):
                        </div>
                    </div>
                    <div style="text-indent: 2em;">
                        <div class="element">您好！</div>
                    </div>
                    <div  style="text-indent: 2em;">
                        <div class="element">{{ textList.text }}</div>
                    </div>
                </div>
                <div class="tui-dialog-footer">
                    <img class="btnImg" src="@/assets/popUp/button.png" alt="" @click="submit">
                </div>
            </div>
        </div>
        <div class="dialogWrap" v-if="textList.type === Constant.DaoJiShi">
            <img src="@/assets/popUp/close.png" alt="" class="closeBtn" @click="close">
            <div class="tui-dialog" style="width: 667px; height: 345px;">
                <div class="tui-dialog-header">
                </div>
                <div class="tui-dialog-body1">
                    <div>
                        <div class="name">
                            {{ name }}(ID：{{ userNo }}):
                        </div>
                    </div>
                    <div style="text-indent: 2em;">
                        <div class="element">您好！</div>
                    </div>
                    <div  style="text-indent: 2em;">
                        <div class="element">
                            {{ textList.text1 }}
                            <span class="redFont">{{ getTime }}</span>
                            {{ textList.text3 }}
                            <span class="redFont">{{textList.text4}}</span>
                            {{textList.text5}}。
                        </div>
                    </div>
                </div>
                <div class="tui-dialog-footer1">
                    <img class="btnImg" src="@/assets/popUp/button.png" alt="" @click="submit">
                </div>
            </div>
        </div>
<!--        <div class="dialogWrap" v-if="textList.type === Constant.ShouChong">-->
<!--            <img src="@/assets/popUp/close.png" alt="" class="closeBtn1" @click="close">-->
<!--            <div class="tui-dialog1" style="width: 525px; height: 559px;">          -->
<!--                <div class="tui-dialog-body1">-->
<!--                    <div class="name">恭喜您</div>-->
<!--                    <div class="name">获得首充特惠</div>-->
<!--                    <div class="name-sub">资深讲师1v1跟踪辅导</div>-->
<!--                    <div class="element1">-->
<!--                        首月仅需-->
<!--                        <span class="big">{{ textList.text }}</span>-->
<!--                        <span class="small">元</span>-->
<!--                    </div>-->
<!--                    <div class="element2">-->
<!--                        每个ID限一次-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="tui-dialog-footer1">-->
<!--                    <img class="btnImg" src="@/assets/popUp/button.png" alt="" @click="submit">-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="dialogWrap" v-if="textList.type === Constant.ShouChong">-->
<!--            <div class="tui-dialog2">-->
<!--                <img src="@/assets/popUp/close.png" alt="" class="closeBtn2" @click="close">-->
<!--                <div class="tui-dialog-body2">-->
<!--                    <div class="money">{{ textList.text }}</div>-->
<!--                    <div class="title">-->
<!--                        <span class="title-one"></span>-->
<!--                        <span class="title-two"></span>-->
<!--                        <span class="title-three"></span>-->
<!--                    </div>-->
<!--                    <div class="left">-->
<!--                        <div class="first">-->
<!--                            <div class="first-title title-common">体验不满意</div>-->
<!--                            <div class="first-bf bf-common">全额退款</div>-->
<!--                        </div>-->
<!--                        <div class="second">-->
<!--                            <div class="second-title title-common">资深讲师</div>-->
<!--                            <div class="second-bf bf-common">1V1授课</div>-->
<!--                        </div>-->
<!--                        <div class="three">-->
<!--                            <div class="three-title title-common">全程陪跑</div>-->
<!--                            <div class="three-bf bf-common">精细辅导</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="middle">-->
<!--                    </div>-->
<!--                    <div class="right">-->
<!--                        <div class="first">-->
<!--                            <div class="first-title title-common">续费尊享</div>-->
<!--                            <div class="first-bf bf-common">{{ textList.text1 }}折优惠</div>-->
<!--                        </div>-->
<!--                        <div class="second">-->
<!--                            <div class="second-title title-common">精选课程</div>-->
<!--                            <div class="second-bf bf-common">免费看</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="tui-dialog-footer2">-->
<!--                    <img class="btnImg" src="@/assets/popUp/button_experience.png" alt="" @click="submit">-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="dialogWrap" v-if="textList.type === Constant.ShouChong">
            <img src="@/assets/popUp/close.png" alt="" class="closeBtn1" @click="close">
            <div class="tui-dialog1" style="width: 525px; height: 559px;">
                <div class="tui-dialog-body1">
                    <div class="name">微信扫一扫</div>
                    <div class="name2">领取一次<span>免费</span>体验资格</div>
                    <div class="sell-wechat">
                        <sellContract></sellContract>
                    </div>
                </div>
                <div class="tui-dialog-footer1">
                    <img class="btnImg" src="@/assets/popUp/button.png" alt="" @click="submit">
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps,defineEmits } from 'vue'
import { ref, computed, onMounted, watch } from 'vue'
import Constant from '@/constant/constant';
import popupNtc from '@/constant/popupNotice';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {CountdownTimer} from "@/util/date";
import sellContract from '@/components/common/sellContract'
const props = defineProps(['popDialog'])
const emits = defineEmits(['popClose','productClose']);
const router = useRouter()
const store = useStore()
const textList = ref({})
const name = computed(() => store.state.user.userView.nickName)
const userNo = computed(() => store.state.user.userView.userName)
// 定义一个定时器
const counterTimer = ref(null)
textList.value = popupNtc.getPopText()
const close = () => {
    emits('popClose',false)
}
const submit = () => {
    emits('productClose',true)
    emits('popClose')
}
const getTime = ref('')
// 格式化倒计时格式
const formatCounter = (days, hours, minutes, seconds, isStop) => {
    getTime.value = ''
    if (!isStop) {
        getTime.value = `${days} 天 ${hours} 小时 ${minutes} 分钟 ${seconds} 秒`
    }
}
watch (() => props.popDialog, (newValue, oldValue) => {
    if (newValue === true) {
        counterTimer.value.start()
    } else {
        counterTimer.value.stop()
    }
})
// 组件挂载时开始倒计时
onMounted(() => {
    let i = textList.value.text2
    // 设置目标日期
    const targetDate = new Date(i+'T23:59:59');
    counterTimer.value = new CountdownTimer(targetDate, 0, formatCounter)
});
</script>

<style lang="less" scoped>
.closeBtn {
    position: fixed;
    width: 40px;
    height: 40px;
    top: calc(50% - 230px);
    left: calc(50% + 340px);
    cursor: pointer;
    pointer-events: all;
}
.closeBtn1 {
    position: fixed;
    width: 40px;
    height: 40px;
    top: calc(50% - 300px);
    left: calc(50% + 250px);
    cursor: pointer;
    pointer-events: all;
}
.maskBox {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background: #000;
    opacity: .6;
    position: fixed;
}
.dialogWrap {
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 600;
    .tui-dialog {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
        background-color: #fff;
        pointer-events: all;
        background: url('@/assets/popUp/back1.png') no-repeat;
        background-size: contain;
        .tui-dialog-footer {
            margin-top: 40px;
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: center;
            .btnImg {
                width: 335px;
                height: 120px;
                cursor: pointer;
            }
        }
        .tui-dialog-footer1 {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: center;
            .btnImg {
                width: 335px;
                height: 120px;
                cursor: pointer;
            }
        }
        .tui-dialog-body {
            font-size: 21px;
            background-image: -webkit-linear-gradient(right, #7A492F, #C97D3B);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: 10px 50px;
            .name {
                border-bottom: 1px solid #7a492f;
                display: inline-block;
            }
            .element {
                text-indent: 0;
                margin-top: 10px;
                border-bottom: 1px solid #7a492f;
                display: inline-block;
            }
        }
        .tui-dialog-body1 {
            font-size: 21px;
            color: #7A492F;
            padding: 10px 50px;
                text-indent: 0;
            .name {
                padding-bottom: 3px;
                text-decoration: underline;
                text-underline-offset: 6px; /* 设置下划线与文字的距离 */
                text-decoration-color: #7a492f;
            }
            .element {
                text-indent: 2em;
                margin-top: 10px;
                text-decoration: underline;
                text-underline-offset: 6px; /* 设置下划线与文字的距离 */
                text-decoration-color: #7a492f;
                text-align: justify;
                .redFont {
                    color: #E4352A;
                    font-size: 26px;
                }
            }
        }
        .tui-dialog-header {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: end;
            height: 48px;
            border-radius: 4px 4px 0 0;
            padding: 0 28px;
            font-size: 18px;
            .close {
                text-align: right;
                font-size: 24px;
                color: rgba(255, 255, 255);
            }
        }
    }
    .tui-dialog1 {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
        background-color: #fff;
        pointer-events: all;
        background: url('@/assets/popUp/back2.png') no-repeat;
        background-size: contain;
        .tui-dialog-footer1 {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: center;
            .btnImg {
                margin-top: 70px;
                width: 335px;
                height: 120px;
                cursor: pointer;
                margin-left: 35px;
            }
        }
        .tui-dialog-body1 {
            font-size: 21px;
            color: #7A492F;
            padding-left: 124px;
            padding-top: 70px;
            .name {
                font-size: 40px;
                background-image: -webkit-linear-gradient(right, #7A492F, #C97D3B);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .name2 {
                font-size: 30px;
                span {
                    font-size: 41px;
                    color: #A43632;
                }
            }
            .sell-wechat {
                text-align: center;
                line-height: 130px;
                margin-top: 10px;
                margin-right: 80px;
                img {
                    vertical-align: middle;
                    border-radius: 10px;
                    width: 118px;
                    height: 118px;
                }
            }
            .element1 {
                font-size: 20px;
                color: #875940;
                margin-top: -20px;
                .big {
                    font-size: 90px;
                    background-image: -webkit-linear-gradient(right, #7A492F, #C97D3B);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-left: 30px;
                }
                .small {
                    font-size: 28px;
                }
            }
            .element2 {
                font-size: 18px;
                color: #875940;
                text-align: right;
                margin-right: 85px;
                margin-top: -12px;
            }
            .name-sub {
                color: #875940;
                font-size: 20px;
            }
        }

    }
    .tui-dialog2 {
        width: 796px;
        height: 768px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
        pointer-events: all;
        color: red;
        /*background-color: RGBA(26, 28, 41, 0.8);*/
        .money {
            width: 193px;
            height: 134px;
            color: #FFCF7E;
            padding-top: 21px;
            padding-left: 0;
            display: inline-block;
            transform: rotate(-30deg);
            font-size: 90px;
            background-image: -webkit-linear-gradient(right, #FFCF7E, #C97D3B);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .title {
            .title-one {
                background: url('@/assets/popUp/title-ti.png') no-repeat;
                background-size: contain;
                position: absolute;
                width: 85px;
                height: 87px;
                top: 37px;
                left: 244px;
            }
            .title-two {
                background: url('@/assets/popUp/title-yan.png') no-repeat;
                background-size: contain;
                position: absolute;
                width: 85px;
                height: 87px;
                top: 15px;
                left: 380px;
            }
            .title-three {
                background: url('@/assets/popUp/title-quan.png') no-repeat;
                background-size: contain;
                position: absolute;
                width: 85px;
                height: 87px;
                top: 0px;
                left: 504px;
            }
        }
        .left {
            .first {
                background: url('@/assets/popUp/love_left.png') no-repeat;
                background-size: contain;
                width: 152px;
                height: 146px;
                position: absolute;
                left: 65px;
                top: 139px;
                animation: scale 1s ease-in-out infinite alternate;
                .first-title {
                    position: absolute;
                    top: 45px;
                    left: 22px;
                    transform: rotate(-30deg);
                }
                .first-bf {
                    position: absolute;
                    top: 66px;
                    left: 45px;
                    transform: rotate(-30deg);
                }
            }
            .second {
                background: url('@/assets/popUp/circle_big.png') no-repeat;
                background-size: contain;
                width: 136px;
                height: 141px;
                position: absolute;
                left: 6px;
                top: 300px;
                animation: scale 1.5s ease-in-out infinite alternate;
                .second-title {
                    position: absolute;
                    top: 41px;
                    left: 23px;
                }
                .second-bf {
                    position: absolute;
                    top: 72px;
                    left: 27px;
                }
            }
            .three {
                background: url('@/assets/popUp/circle_big.png') no-repeat;
                background-size: contain;
                width: 136px;
                height: 141px;
                position: absolute;
                left: 78px;
                top: 370px;
                animation: scale 2s ease-in-out infinite alternate;
                .three-title {
                    position: absolute;
                    top: 39px;
                    left: 26px;
                }
                .three-bf {
                    position: absolute;
                    top: 70px;
                    left: 26px;
                }
            }
        }
        .right {
            .first {
                background: url('@/assets/popUp/circle_big.png') no-repeat;
                background-size: contain;
                width: 125px;
                height: 132px;
                position: absolute;
                right: 108px;
                top: 85px;
                animation: scale 2.5s ease-in-out infinite alternate;
                .first-title {
                    position: absolute;
                    top: 35px;
                    left: 25px;
                    font-size: 18px;
                }
                .first-bf {
                    position: absolute;
                    top: 62px;
                    left: 16px;
                    font-size: 20px;
                }
            }
            .second {
                background: url('@/assets/popUp/love_right.png') no-repeat;
                background-size: contain;
                width: 150px;
                height: 149px;
                position: absolute;
                right: 88px;
                top: 277px;
                animation: scale 3s ease-in-out infinite alternate;
                .second-title {
                    position: absolute;
                    top: 47px;
                    left: 38px;
                    transform: rotate(30deg);
                }
                .second-bf {
                    position: absolute;
                    top: 72px;
                    left: 31px;
                    transform: rotate(30deg);
                }
            }
        }

        .middle {
            width: 355px;
            height: 490px;
            background: url('@/assets/popUp/gift_box.png') no-repeat;
            background-size: contain;
            position: absolute;
            left: 190px;
            top: 122px;
        }
        .tui-dialog-footer2 {
            position: absolute;
            width: 334px;
            height: 121px;
            left: 188px;
            bottom: 0px;
            .btnImg {
                width: 335px;
                height: 120px;
                cursor: pointer;
            }
        }
        .closeBtn2 {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 0px;
            right: 0px;
            cursor: pointer;
            pointer-events: all;
        }
        .title-common {
            color: #E8DCE9;
            font-size: 20px;
        }
        .bf-common {
            color:#FF603B;
            font-size: 22px;
        }
        @keyframes scale {
            from {
                transform: scale(1);
            }
            to {
                transform: scale(1.2);
            }
        }
    }
}
</style>