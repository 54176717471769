/**
 * 专门处理store的数据
 */
import store from '@/store'
import Constant from './constant'
const popupNtc = {
    //设计权限
    getPopText() {
        let popupType = store.state.user.popup.popupType
        let userStatus = store.state.user.popup.userStatus
        let expireDate = store.state.user.popup.expireDate
        let secondDiscount = store.state.user.popup.secondDiscount
        let firstPrice = store.state.user.popup.firstPrice
        let type,text
        let text1 = '',text2 = '',text3 = '',text4 = '',text5 = ''
        if(popupType === Constant.ShouChong) {
            type = Constant.ShouChong
            text = firstPrice
            text1 = secondDiscount
        }else if(popupType === Constant.DaoJiShi) {
            type = Constant.DaoJiShi
            if(userStatus === Constant.UserStatusNormal) {
                text1 = '您的会员即将到期，'
                text2 = expireDate
                text3 = '续费可享'
                text4 = secondDiscount+"折"
                text5 = '优惠'
            }else if(userStatus === Constant.UserStatusExpired) {
                text1 = '您的会员已到期，请续费恢复使用权限，'
                text3 = '续费可享'
                text4 = secondDiscount+"折"
                text5 = '优惠'

            }

        }else if(popupType === Constant.TiXing) {
            type = Constant.TiXing
            if(userStatus === Constant.UserStatusExpired) {
                text = '您的会员已到期，请及时恢复使用权限。'

            }else if(userStatus === Constant.UserStatusForbidden) {
                text = '您的会员已被禁用，请联系官方平台。'
                
            }else if(userStatus === Constant.UserStatusFree) {
                text = '开通会员，享更多会员权益'
            }
            
        }else if(popupType === Constant.Empty) {
            type = Constant.Empty 
        }
        return {
            type:type,
            text:text,
            text1:text1,
            text2:text2,
            text3:text3,
            text4:text4,
            text5:text5,
        }

    }
}

export default popupNtc