// 计算日期差值（闰年，平年）

export function getDateSpacing(startDate, endDate) {
    const date = new Date(startDate) // 转换时间格式
    const year = date.getFullYear() // 取当年的年
    const month = date.getMonth() + 1 // 取当年的月(月份加一)
    const dd = date.getDate() // 取当年的日期
    const nowDate = new Date(endDate) // 取现在的时间
    const nowYear = nowDate.getFullYear() // 取现在的年
    const nowMonth = nowDate.getMonth() + 1 // 取现在的月(月份加一)
    const nowDd = nowDate.getDate() // 取现在的日期
    /**
     * 日期计算(结束 - 开始)
     * 1-1当差值为正，就相差多少天
     * 1-1-1例如: (2021/3/15 - 2022/4/18) ===== 18-15 > 0 日期相差3天
     * 1-2当差值为负，计算从开始时间的日期到结束时间的日期相差几天
     * 1-2-1例如：(2021/3/15 - 2022/4/10) ===== 10-15 < 0
     * 其实就是计算从3/15 到 4、10号中间过了多少天
     * 1-2-1-1：方法： 其实就是计算3/15 到 3/31 号过了多少天，再加上 4月过的10天
     */
    const restDd =
        nowDd - dd < 0 ? lastDay(nowMonth - 1, year) - dd + nowDd : nowDd - dd
    /**
     * 月份计算(结束 - 开始)
     * 1-1当差值为正，就相差多少月
     * 1-1-1例如: (2021/3/15 - 2022/4/18) ===== 4-3 > 0 月份相差1月
     * 1-2当差值为负，计算从开始时间的月份到结束时间的月份相差几天
     * 1-2-1例如：(2021/5/15 - 2022/4/10) ===== 4-5 < 0
     * 其实就是计算从5月到第二年4月过了多少月
     * 1-2-1-1：方法： 向年借一年为12月计算过了多少月
     */
    const restMonth =
        nowMonth - month < 0 ? 12 + nowMonth - month : nowMonth - month
    /**
     * 年份计算(结束 - 开始)
     * 直接限制结束比开始大，只有0/正数
     */
    const restYear = nowYear - year
    /**
     * 计算借位的问题
     */
    let resultMonth = restMonth
    let resultYear = restYear
    // 日期小说明借了月
    if (nowDd < dd) {
        resultMonth = restMonth - 1 < 0 ? restMonth - 1 + 12 : restMonth - 1
    }
    // 月份小借了年 或者 日期小，月份刚好一致，因为日期借了月份，导致月份减1，所以减年
    if (nowMonth < month || (nowDd < dd && nowMonth === month)) {
        resultYear = restYear - 1
    }
    let str = {
        year: resultYear,
        month: resultMonth,
        day: restDd
    }
    return str
}

/**
 * 判断每年的每个月的最后一天是几号
 * @param mo-月份
 * @param year-年份
 * @returns {number}
 */
function lastDay(mo, year) {
    if (mo === 4 || mo === 6 || mo === 9 || mo === 11) {
        return 30
    }
    //2月
    else if (mo === 2) {
        if (isLeapYear(year)) {
            return 29
        } else {
            return 28
        }
    }
    //大月
    else {
        return 31
    }
}

/**
 * 判断是否是闰年
 * @param Year-年份
 * @returns {boolean}
 */
function isLeapYear(Year) {
    return (Year % 4 === 0 && Year % 100 !== 0) || Year % 400 === 0
}

// 倒计时基础类
export class CountdownTimer {
    // deadline 格式为年月日时分秒;
    // beginHour 开始倒计时的小时精确到小时;
    // callback 回调函数;用户展示业务数据
    constructor(endTime, beginHour = 0, callback) {
        this.endTime = endTime;
        this.beginHour = beginHour;
        this.callback = callback;
        this.intervalId = null;
    }

    // 开启定时器
    start() {
        this.intervalId = setInterval(() => {
            // 获取当前时间
            const now = new Date().getTime();
            // 计算时间差，即剩余的毫秒数
            const timeLeft = this.endTime - now;
            // 一小时的毫秒数
            const oneHourMilliseconds = 60*60*1000
            // 是否已经倒计时到0
            let isStop = false
            if (timeLeft > 0) {
                // 计算剩余的天数
                const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
                // 计算剩余的小时数
                const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                // 计算剩余的分钟数
                const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                // 计算剩余的秒数
                const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
                if (days <=0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
                    isStop = true
                }
                if (isStop === true) {
                    this.stop();
                    this.callback(0, 0, 0, 0, isStop);
                } else {
                    // 没有设置时间时显示倒计时
                    if (this.beginHour <= 0) {
                        this.callback(days, hours, minutes, seconds, isStop)
                    } else if (this.beginHour > 0 && timeLeft <= this.beginHour*oneHourMilliseconds) {
                        // 小于指定时间开始显示倒计时
                        this.callback(days, hours, minutes, seconds, isStop)
                    } else {
                        this.stop();
                        this.callback(0, 0, 0, 0, true);
                    }
                }
            } else {
                this.stop();
                this.callback(0, 0, 0, 0, true);
            }
        }, 1000);
    }

    // 清除定时器
    stop() {
        this.intervalId && clearInterval(this.intervalId);
    }
}