import { createStore } from 'vuex'
import user from "./modules/user";
import buy from "./modules/buy";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    buy,
  }
})