<template>
    <div class="price-page">
        <div class="priceTitle">
            高 效 设 计 , 打 造 理 想 庭 院 空 间
        </div>
        <div class="priceBox">
            <div class="priceList" v-for="item in sellData" :key="item.tplId">
                <div class="title">{{ item.name }}</div>
                <div class="price" v-for="(i, index) in item.product" :key="i.productId">
                    <span v-if="index === 0">¥ {{ i.payAmount }}</span>
<!--                    <span v-if="i.expireCode === 'expire_one_year'">¥ {{ i.payAmount }}</span>-->
                </div>
                <div class="price" v-if="item.productType == 'product_free'">
                    <span>¥ 免费</span>
                </div>
                <div class="price" v-if="item.productType == 'product_ask'">
                    <span>¥ 适用于团队</span>
                </div>
                <div class="btn" v-if="item.productType == 'product_ask'" @click="handleClickBuy(item)">立即咨询</div>
                <div class="btn" v-else-if="item.productType == 'product_free'" @click="routerToDesign">立即使用</div>
                <div class="btn" v-else @click="handleClickBuy(item)">立即购买</div>
                <div class="ul">
                    <div class="li" v-for="(j,index) in item.tags" :key="index"><span class="openStatus"></span>{{ j }}
                    </div>
                </div>
            </div>
        </div>
        <div class="price-tip">
            说明：您可在两个设备上登录您的账号，但同一账号同一时间仅支持在一个设备进行设计创作
        </div>
        <PayDialog v-if="payDialog || productDialog" :payDialog="payDialog" :productDialog="productDialog" :selectItem="selectItem"
                   @payClose="payClose" @productClose="productClose"/>

    </div>
</template>

<script setup>
import Constant from '@/constant/constant';
import PayDialog from '@/components/common/payDialog';
import {computed, ref} from 'vue'
import {productSell,} from '@/api/ware.js';
import {useStore} from "vuex";
import {useRouter} from 'vue-router'

const payDialog = ref(false)
const productDialog = ref(false)
const payClose = (val) => {
    payDialog.value = val || false
}
const router = useRouter()
const productClose = (val) => {
    productDialog.value = val || false
}
const store = useStore()
const popVisible = ref(false)
const onClickOutside = () => {
    popVisible.value = false
}

const selectItem = ref([])
const sellData = ref([])
const isLogin = computed(() => {
    return localStorage.getItem(Constant.HttpAccessToken) || false;
})
const routerToDesign = async () => {
    if (isLogin.value) {
        router.push('/p')
        // window.open(process.env.VUE_APP_DESIGN_URL)
    } else {
        await store.dispatch('getWeChatSetting');
        store.commit('SET_LOGIN_MODAL_VISIBLE', {
            visible: true,
            type: 'wxLogin',
            path: '/m',
        });
    }
};
const getSellDate = () => {
    const userId = store.state.user?.userView?.id || 0
    productSell({userId: userId}).then(res => {
        sellData.value = res.result
    });
}
getSellDate()
const handleClickBuy = async (item) => {
    if (isLogin.value) {
        getSellDate()
        selectItem.value = item
        productDialog.value = true
    } else {
        await store.dispatch('getWeChatSetting');
        store.commit('SET_LOGIN_MODAL_VISIBLE', {
            visible: true,
            type: 'wxLogin',
            path: '/p',
        });
    }
}


</script>

<style lang="scss" scoped>
.phoneBox {
	font-size: 14px;
	font-weight: 700;
	.label {
		display: inline-block;
	}
	.value {
		display: inline-block;
		padding-left: 10px;
	}
}
.imgBox {
	margin-top: 10px;
	img {
		width: 180px;
	}
}
.price-page {
	width: 1200px;
	margin: 0 auto;
	overflow: hidden;
}
.priceTitle {
	font-size: 42px;
	color: #fdfdfd;
	text-align: center;
	margin-top: 80px;
	margin-bottom: 50px;
	font-weight: 600;
}
.priceBox {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fdfdfd;
	flex-wrap: wrap;
	.fillSpan {
		width: 280px;
		height: 0;
	}
	.priceList {
		text-align: center;
		padding: 35px 30px;
		box-sizing: border-box;
		height: 410px;
		width: 280px;
		background: url('@/assets/price/block.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		margin-left: 20px;
		cursor: pointer;
		.title {
			font-size: 24px;
		}
		.price {
			font-size: 18px;
			margin-top: 20px;
		}
		.btn {
			font-size: 18px;
			border: 2px solid #fdfdfd;
			padding: 5px 0;
			border-radius: 8px;
			margin-top: 20px;
		}
		.ul {
			margin-top: 15px;
		}
		.li {
			float: left;
			width: calc(100% - 15px);
			overflow:hidden;
			text-overflow:ellipsis;//文本溢出显示省略号
			white-space:nowrap;//文本不会换行
			position: relative;
			padding-left: 15px;
			box-sizing: border-box;
			font-size: 14px;
			text-align: left;
			margin-top: 12px;
			.openStatus::before {
				content: "";
				width: 6px;
				height: 6px;
				position: absolute;
				top: 10px;
				left: 0px;
				border-radius: 50%;
				background-color: #fdfdfd;
			}
		}
		&:nth-child(4n+1) {
			margin-left: 0;
		}
		&:hover {
			background-image: url('@/assets/price/red.png');
			.btn {
				background-color: #AE3B34;
			}
		}
	}
}
.price-tip {
	margin: 24px  auto;
    width: 960px;
    font-family: PingFangSC-Regular;
    height: 54px;
    line-height: 54px;
    background: #2B2E42;
    border-radius: 8px;
    font-size: 14px;
    color: #fdfdfd;
    letter-spacing: 0;
    text-align: center;
    padding-left: 16px;
    font-weight: 400;
}
</style>