<template>
    <!-- 加载状态显示 -->
    <div v-if="isLoading.value" class="loading">加载中...</div>
    <!-- 错误状态显示 -->
    <div v-else-if="hasError.value" class="error">图片加载失败</div>
    <!-- 正常显示图片 -->
    <img
        v-else
        :src="versionedSrc"
        :alt="alt"
        :width="width"
        :height="height"
        @load="onLoad"
        @error="onError"
        :loading="lazy ? 'lazy' : 'eager'"
    />
</template>

<script setup>
    import { ref } from 'vue';
    const props = defineProps(['src', 'width', 'height', 'lazy', 'alt']);
    const isLoading = ref(true);
    const hasError = ref(false);
    const version = Date.now()
    const versionedSrc = `${props.src}?v=${version}`;
    // 图片加载成功时的处理函数
    const onLoad = () => {
        isLoading.value = false;
        hasError.value = false;
    };
    // 图片加载失败时的处理函数
    const onError = () => {
        isLoading.value = false;
        hasError.value = true;
    };
</script>

<style lang="scss" scoped>
.loading {
    color: #999;
    font-size: 14px;
}
.error {
    color: red;
    font-size: 14px;
}
</style>