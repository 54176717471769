<template>
    <div class="wrapper" @scroll="(e) => navScrollTop = e.target.scrollTop">
        <div class="login">
            <div class="videoBox">
                <HomeHeader :isShowNav="isShowNav"/>

                <video class="video-background" autoplay="autoplay" loop="loop" muted="muted">
                    <source src="@/assets/banner.mp4" type="video/mp4"/>
                </video>
            </div>
            <div class="home-desc">
                <div class="home-tit-bg"><img src="@/assets/images/3dbanner.png" alt=""></div>
                <p>让花园设计如此简单</p>
                <div class="button-list">
                    <div class="button-design" @click="handleStart">个人免费试用</div>
                    <div class="button-bug" @click="handlePay">商业付费开通</div>
                    <!-- <el-popover placement="bottom" :width="200" trigger="click" v-else>
                        <div class="phoneBox">
                        <div class="label">
                            <el-icon><Phone /></el-icon>
                        </div>
                        <div class="value">
                            0314-2539999
                        </div>
                        </div>
                        <div class="imgBox">
                            <SellContract></SellContract>
                        </div>
                        <template #reference>
                            <div class="button-design">个人免费使用</div>
                        </template>
                    </el-popover> -->
                    <!-- <el-popover placement="bottom" :width="200" trigger="click">
                        <div class="phoneBox">
                            <div class="label">
                                <el-icon><Phone /></el-icon>
                            </div>
                            <div class="value">
                                0314-2539999
                            </div>
                        </div>
                        <div class="imgBox">
                            <SellContract></SellContract>
                        </div>
                        <template #reference>
                            <div class="button-bug">商业付费开通</div>
                        </template>
                    </el-popover> -->
                </div>
            </div>
            <LoginComp/>
        </div>
        <Exhibition/>
        <HomeFooter/>
        <PayDialog  v-if="payDialog || productDialog" :payDialog="payDialog" :productDialog="productDialog" :selectItem="selectItem"
                   @payClose="payClose" @productClose="productClose"/>
    </div>
</template>

<script setup>
import PayDialog from '@/components/common/payDialog';
import {computed, onMounted, ref} from 'vue'
import LoginComp from '@/pages/login/login_form/loginComp.vue';
import {useRoute, useRouter} from 'vue-router'
import Exhibition from './exhibition/exhibition.vue';
import HomeFooter from './homeFooter.vue';
import HomeHeader from './homeHeader.vue';
import Constant from '@/constant/constant';
import {useStore} from "vuex"
import PersonPerm from '@/constant/personPerm';
import {productSell,} from '@/api/ware.js';
import SellContract from '@/components/common/sellContract'

const payDialog = ref(false)
const productDialog = ref(false)
const payClose = (val) => {
    payDialog.value = val || false
}
const productClose = (val) => {
    productDialog.value = val || false
}
const selectItem = ref([])
const getSellDate = () => {
    const userId = store.state.user?.userView?.id || 0
    productSell({userId: userId}).then(res => {
        let arr = res.result
        arr.filter(res => {
            if (res.productType == 'product_ask') {
                selectItem.value = res
            }
        })
    });
}
onMounted(async () => {
    await store.dispatch('getWeChatSetting');
    jumpToLogin();
})
const store = useStore()
const userStatus = computed(() => {
    return PersonPerm.design() || false
})
const route = useRoute()
const router = useRouter()
const navScrollTop = ref(0);
const isShowNav = computed(() => {
    return navScrollTop.value > window.innerHeight;
})

const isLogin = computed(() => {
    return localStorage.getItem(Constant.HttpAccessToken) || false;
})


if (route.query.code) {
    store.dispatch('WxLogin', {code: route.query.code, state: route.query.state}).then(res => {
        if (res.flag === Constant.wechatNotBind) {
            store.commit('SET_LOGIN_MODAL_VISIBLE', {
                visible: true,
                type: 'bind',
            });
        }
    })
}
const handleStart = async () => {
    if (isLogin.value) {
        return window.location.href = '/p';
    }
    await store.dispatch('getWeChatSetting');
    store.commit('SET_LOGIN_MODAL_VISIBLE', {
        visible: true,
        type: 'wxLogin',
        path: '/p',
    });

}
const handlePay = async () => {
    if (isLogin.value) {
        getSellDate()
        return productDialog.value = true;
    }
    await store.dispatch('getWeChatSetting');
    store.commit('SET_LOGIN_MODAL_VISIBLE', {
        visible: true,
        type: 'wxLogin',
        path: '/p',
    });

}


const jumpToLogin = () => {
    const params = new URLSearchParams(window.location.search);
    const fromPath = params.get('from');
    if (fromPath) {
        store.commit('SET_LOGIN_MODAL_VISIBLE', {
            visible: true,
            type: 'wxLogin',
            path: '/p',
        });
    }
}
</script>

<style lang="scss" scoped>
.phoneBox {
	font-size: 14px;
	font-weight: 700;
}
.imgBox {
	margin-top: 10px;
	img {
		width: 180px;
	}
}
.label {
	display: inline-block;
}
.value {
	display: inline-block;
	padding-left: 10px;
}
.wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
}
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .videoBox {
    height: 100vh;
    top: 0;
    position: relative;
    width: 100%;
	.home-header {
		display: flex;
		position: absolute;
		padding: 20px 20px;
		align-items: center;
		z-index: 10;
		width: 100%;
		background: rgba(29, 33, 41, 0.6);
		position: fixed;
		.nav {
			margin: 0;
			display: flex;
			align-items: center;
			margin-left: 70px;
			padding: 0;
			>li {
				
				color: #fff;
				list-style: none;
				margin-right: 30px;
				cursor: pointer;
			}
		}
		.login-area {
			margin-left: auto;
			margin-right: 40px;
			color: #fff;
			cursor: pointer;
		}
	}
    .video-background {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  h1 {
    color: #2148f6;
  }
  .home-desc {
	position: absolute;
	left: 15%;
	top: 30%;
	z-index: 9;
	color: #fff;
	>p{
		font-size: 24px;
		letter-spacing: 2px;
		margin-bottom: 40px;
		margin-top: 25px;
	}
  }
  .content {
	position: fixed;
	margin: auto;
    display: flex;
    align-items: center;
    z-index: 19;
	background: rgba($color: #000000, $alpha: 0.5);
	display: flex;
    align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
    .form {
      box-sizing: border-box;
      width: 400px;
      background-color: #fff;
      border-radius: 8px;
      padding: 40px 30px;
    }
  }
  .switchover {
    display: flex;
    justify-content: space-between;
    color: #2148f6;
    margin-top: 20px;
    span {
      cursor: pointer;
    }
    .icon {
      width: 13px;
      height: 11px;
    }
  }
}

.button-base-style {
	width: 200px;
	height: 50px;
	line-height: 50px;
	font-size: 18px;
	font-weight: 700;
	text-align: center;
	border-radius: 8px;
	cursor: pointer;
}
.button-list {
	display: flex;
	.button-bug {
		@extend .button-base-style;
		background-color: #fff;
		color: #000;
	}
	.button-design {
		@extend .button-base-style;
		background-color: #cf3861;
		color: #fff;
		margin-right: 20px;
	}
}

.custom-button {
	background-color: #cf3861;
	border: #cf3861;
}

</style>
