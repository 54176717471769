import { createRouter, createWebHistory } from 'vue-router'
import Constant from '@/constant/constant';
import UserPerm from '@/constant/userPerm';
import store from '@/store'
import HomePage from '../pages/login/login.vue';
import ManageTemp from '../pages/manage.vue';
import BlankTemp from '../pages/routerTo/index.vue';
import PersonalSchema from '../pages/schemLibManage/personScheme.vue';
import schemeManage from '../pages/schemLibManage/schemeManage.vue';
import SettingTemp from '@/pages/person/personSetting.vue';
import PersonMaterial from '../pages/material/personMaterial.vue';
import MyCollection from '../pages/material/myCollection.vue';
import MaterialUpload from '../pages/material/materialUpload.vue';
import TextureUpload from '../pages/material/textureUpload.vue';
import PriceComp from '@/pages/login/price/index.vue';
import designComp from '@/pages/login/design/index.vue';
import designDetail from '@/pages/login/designDetail/index.vue';
import materialComp from '@/pages/login/material/index.vue';
import HomeTemp from '../pages/login/homeTemp.vue';
import QrCode from '@/pages/qrCode.vue';
const routes = [
	{
		path: '/',
		name: 'homePage',
		meta: {
			title: "首页",
			code:'',
			home:true,
		},
		component: HomePage,
	},
	{
		path: '/qrCode',
		name: 'qrCode',
		meta: {
			title: "支付跳转",
			code:'',
		},
		component: QrCode,
	},
	
	{
		path: '/',
		name: "home",
		meta: {
			title: "首页",
			code:'',
		},
		redirect: '/home',
		component: HomeTemp,
		children: [
			{
				path: 'price',
				name: 'price',
				meta: {
					title: '价格',
					code:'',
					auth:true,
					show:true,
					// description: 'descriptiondescription',
					// metaInfo: {
					// 	title: "在线工具箱-By Mintimate",
					// 	description:"Mintimate的在线工具箱，致力于打造便民服务。为开发者、Minecraft游戏爱好者，创建方便的在线工具",
					// 	keywords: "在线工具箱,便民根据,站点根据,开发者,开发者实用工具,IP查询,图片处理,Base64转码,字数统计,字符统计,MC服务器检测,Minecraft服务器检测",
					// }
				},
				component: PriceComp,
			},
			{
				path: 'design',
				name: 'design',
				meta: {
					title: '优秀设计',
					code:'',
				},
				component: () => import("@/pages/login/design/oldDesign.vue"),
			},
			{
				path: 'template',
				name: 'ctb',
				meta: {
					title: '模板中心',
					code:'',
					auth:true,
					show:true
				},
				component: designComp,
			},
			{
				path: '/template/dtl/:id',
				name: 'designDetail',
				meta: {
					title: '模板中心详情',
					code:'',
					auth:true,
					show:true
				},
				component: designDetail,
			},
			{
				path: "template/create",
				name: 'ctbCreate',
				meta: {
					title: "方案投稿",
					code: '',
					auth:true,
					show:true
				},
				component: () => import("@/pages/login/proposalSubmission/index.vue"),
			},
			{
				path: "template/edit",
				name: 'template/edit',
				meta: {
					title: "方案投稿编辑",
					code: '',
					auth:true,
					show:true
				},
				component: () => import("@/pages/login/proposalSubmission/index.vue"),
			},
			{
				path: 'study',
				name: 'study',
				meta: {
					title: '学习中心',
					code:'',
				},
				component: () => import('@/pages/login/studyCenter/index.vue'),
			},
			{
				path: '/f',
				name: 'anthor',
				component: () => import('@/pages/login/anthor/index.vue'),
				children: [
					{
						path: 'help/:id',
						name: "help",
						meta: {
							title: "帮助",
							code: '',
						},
						component: () => import("@/pages/login/anthor/category/index.vue"),
					},
					{
						path: "help/article/:id",
						name: 'help/article',
						meta: {
							title: "帮助",
							code: '',
							home:true,
							show:true,
							auth:true,
						},
						component: () => import("@/pages/login/anthor/article/index.vue"),
					}
				],
			},
			{
				path: 'materials',
				name: 'materials',
				meta: {
					title: '素材',
					code:'',
				},
				component: materialComp,
			},
			{
				path: 'upgrades',
				name: 'upgrades',
				meta: {
					title: '升级页面',
					code:'',
				},
				component: () => import('@/pages/login/upgrades/index.vue'),
			},
			{
				path: 's',
				name: 'sandz',
				meta: {
					title: '协议',
				},
				component: () => import('../pages/routerTo/index.vue'),
				children: [
					{
						path: 'agreement',
						name: 'agreement',
						meta: {
							title: '用户服务协议',
							code:'',
						},
						component: () => import('@/pages/login/agreement/index.vue'),
					},
					{
						path: 'agree-upload',
						name: 'agree-upload',
						meta: {
							title: '图布斯3D花园云设计平台用户上传协议',
							code:'',
						},
						component: () => import('@/pages/login/protocolUpload/index.vue'),
					},
					{
						path: 'privacy',
						name: 'privacy',
						meta: {
							title: '隐私政策',
							code:'',
						},
						component: () => import('@/pages/login/privacy/index.vue'),
					},
					{
						path: 'agree-pay',
						name: 'agreePay',
						meta: {
							title: '付费服务购买协议',
							code:'',
						},
						component: () => import('@/pages/login/agreePay/index.vue'),
					},
				]
			},
			{
				path: 'about',
				name: 'about',	
				meta: {
					title: '关于我们',
					code:'',
				},
				component: () => import('@/pages/about/index.vue'),
			}
		]
	},
	{
		path: '/:catchAll(.*)',
		// name: '404page',
		component: () => import('@/pages/404/index.vue'),
	},
	{
		path: '/p',
		name: 'manage123',
		component: ManageTemp,
		children: [
			{
				path: '',
				name: '',
				meta: {
					title: '主页',
					auth:true,
					show:false,
				},
				component: () => import('../pages/home/index.vue'),
			},
			{
				path: 'project',
				name: 'project',
				meta: {
					title: '方案',
					auth:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "/p/project/ps",
				children: [ {
						path: 'ps',
						name: 'personScheme',
						meta: {
							title: '我的方案',
							auth:true,
						},
						component: PersonalSchema,
					},
					{
						path: 'sm',
						name: 'schemeManage',
						meta: {
							title: '方案详情',
							auth:true,
						},
						component: schemeManage,
					},
				]
			},
			{
				path: 'open',
				name: 'open',
				meta: {
					title: '开放素材',
					auth:true,
				},
				component: () => import('../pages/freeMaterial/index.vue'),
			},
			{
				path: 'm/sub',
				name: "sub",
				meta: {
					title: "子账户",
					code: Constant.PermV2ComCenterSubAccount,
					perm:true,
					auth:true,
				},
				component: () => import("../pages/account/subAccounts.vue"),
			},
			{
				path: 'm/dir',
				name: "dir",
				meta: {
					title: "素材目录",
					code: Constant.PermV2ComCenterDir,
					perm:true,
					auth:true,
				},
				component: () => import("../pages/dir/index.vue"),
			},
			{
				path: 'material',
				name: 'material',
				meta: {
					title: '我的素材',
					auth:true,
				},
				// component: PersonMaterial,
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "/p/material/me",
				children: [
					{
						path: 'me',
						name: 'meMaterial',
						meta: {
							title: '我的素材',
							auth:true,
						},
						component: PersonMaterial,
					},
					{
						path: 'clt',
						name: 'clt',
						meta: {
							title: '我的收藏',
							auth:true,
						},
						component: MyCollection,
					},
					{
						path: 'mu',
						name: 'materialUpload',
						meta: {
							title: '上传模型',
							code: Constant.PermMaterialModelUpload,
							perm:true,
							auth:true,
						},
						component: MaterialUpload,
					},
					{
						path: 'tu',
						name: 'textureUpload',
						meta: {
							title: '上传材质',
							code: Constant.PermMaterialTextureUpload,
							perm:true,
							auth:true,
						},
						component: TextureUpload,
					},
				]
			},
			{
				path: 'recovery',
				name: 'recovery',
				meta: {
					title: '回收站',
					auth:true,
					show:false,
				},
				component: () => import('../pages/backstageOther/recovery.vue'),
			},
		]
	},
	{
		path: '/m',
		name: 'manage',
		component: ManageTemp,
		redirect: '/p',
		children: [
			{
				path: "sys",
				name: 'sys',
				meta: {
					title: "系统设置",
					code: Constant.PermV2PlatSys,
					auth:true,
					show:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "/m/sys/dict",
				children: [{
						path: 'dict',
						name: "dict",
						meta: {
							title: "数据字典",
							code: Constant.PermV2PlatSysDict,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/dict.vue"),
					},{
					path: 'tag',
					name: "tag",
					meta: {
						title: "资源标签",
						code: Constant.PermV2PlatSysTag,
						perm:true,
						show:true,
						auth:true,
					},
					component: () => import("../pages/systemSet/material.vue"),
					},
					{
					path: 'cate/material',
					name: "cate/material",
					meta: {
						title: "公共材质分类",
						code: Constant.PermV2PlatSysImageCate,
						perm:true,
						show:true,
						auth:true,
					},
					component: () => import("../pages/systemSet/materialTree.vue"),
					},{
					path: 'cate/model',
					name: "cate/model",
					meta: {
						title: "公共模型分类",
						code: Constant.PermV2PlatSysModelCate,
						perm:true,
						show:true,
						auth:true,
					},
					component: () => import("../pages/systemSet/modelTree.vue"),
					},
					{
						path: 'model/feature',
						name: "model/feature",
						meta: {
							title: "模型特性分类",
							code: Constant.PermV2PlatSysModelFeature,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/featuresTree.vue"),
					}
				]
			},
			{
				path: "noPerm",
				name: 'noPerm',
				meta: {
					title: "暂无权限",
					auth:true,
					show:false,
				},
				component: () => import('../pages/systemSet/noPerm.vue'),
			},
			{
				path: "user",
				name: 'user',
				meta: {
					title: "账户权限",
					code: Constant.PermV2PlatAccount,
					auth:true,
					show:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "/m/user/company",
				children: [{
						path: 'company',
						name: "company",
						meta: {
							title: "企业管理",
							code: Constant.PermV2PlatAccountCom,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/account/merchantSettlement.vue"),
					},{
					path: 'account',
					name: "account",
					meta: {
						title: "账号管理",
						code: Constant.PermV2PlatAccountMag,
						perm:true,
						show:true,
						auth:true,
					},
					component: () => import("../pages/account/accountManagement.vue"),
					},{
					path: 'role',
					name: "role",
					meta: {
						title: "平台权限",
						code: Constant.PermV2PlatAccountRole,
						perm:true,
						show:true,
						auth:true,
					},
					component: () => import("../pages/account/platformPermissions.vue"),
					},
				]
			},
			{
				path: "dsg",
				name: 'dsg',
				meta: {
					title: "设计工具",
					code: Constant.PermV2PlatTool,
					auth:true,
					show:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "/m/dsg/quality",
				children: [
					{
						path: 'quality',
						name: "quality",
						meta: {
							title: "渲染清晰度",
							code: Constant.PermV2PlatToolQuality,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/render.vue"),
					},
					{
						path: 'base',
						name: "base",
						meta: {
							title: "基础权益",
							code: Constant.PermV2PlatToolBase,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/memberBenefits.vue"),
					},
					{
						path: 'tpl',
						name: "tpl",
						meta: {
							title: "权益模板",
							code: Constant.PermV2PlatToolTpl,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/equityTemplate.vue"),
					},
					{
						path: 'project/tpl',
						name: "project/tpl",
						meta: {
							title: "方案模板",
							code: Constant.PermV2PlatToolProjectTpl,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/planMould.vue"),
					},
					{
						path: 'smr',
						name: "smr",
						meta: {
							title: "投稿审核",
							code: Constant.submissionReview,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/submissionReview.vue"),
					}
				]
			},
			{
				path: "shop",
				name: 'shop',
				meta: {
					title: "商品中心",
					code: Constant.PermV2PlatShop,
					auth:true,
					show:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "/m/user/product",
				children: [
					{
						path: 'product',
						name: "product",
						meta: {
							title: "商品管理",
							code: Constant.PermV2PlatShopMag,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/ware.vue"),
					},
					{
						path: 'order',
						name: "order",
						meta: {
							title: "订单管理",
							code: Constant.PermV2PlatShopOrder,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/orderList.vue"),
					}
				]
			},
			{
				path: "msg",
				name: 'msgCenter',
				meta: {
					title: "消息中心",
					code: Constant.PermV2PlatMsg,
					perm:true,
					auth:true,
					show:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				children: [
					{
						path: 'announce',
						name: "announce",
						meta: {
							title: "系统公告",
							code: Constant.PermV2PlatMsgNotice,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/notification/index.vue"),
					}
				]
			},
			{
				path: "sem",
				name: 'sem',
				meta: {
					title: "营销推广",
					code: Constant.PermV2PlatSem,
					auth:true,
					show:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				children: [{
						path: 'coupon',
						name: "coupon",
						meta: {
							title: "优惠券管理",
							code: Constant.PermV2PlatSemCoupon,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/coupon/index.vue"),
					}
				]
			},
			{
				path: "resource",
				name: 'resource',
				meta: {
					title: "资源中心",
					code: Constant.PermV2PlatResource,
					auth:true,
					show:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				children: [{
						path: 'article',
						name: "article",
						meta: {
							title: "文档管理",
							code: Constant.PermV2PlatResourceArticle,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/resources/text.vue"),
					},
					{
						path: 'cate',
						name: "cate",
						meta: {
							title: "文章分类",
							code: Constant.PermV2PlatResourceCate,
							perm:true,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/articleTree.vue"),
					},
				]
			},
			{
				path: "mis",
				name: 'mis',
				meta: {
					title: "任务中心",
					code: Constant.PermV2PlatMis,
					auth: false,
					show:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				children: [
					{
						path: 'pre-save',
						name: "pre-save",
						meta: {
							title: "预缴金额设置",
							code: Constant.PermV2PlatMisPre,
							show: true,
							perm:true,
							auth: true,
						},
						component: () => import("../pages/missionSettings/price/index.vue"),
					},
					{
						path: "audit",
						name: 'audit',
						meta: {
							title: "任务审核",
							code: Constant.PermV2PlatMisCredit,
							show: true,
							auth:true,
							perm:true,
						},
						component: () => import('../pages/audit/index.vue'),
					},
				]
			}
			
		]
	},
	{
		path: '/pub',
		name: 'person',
		component: SettingTemp,
		redirect: '/pub/set/ps',
		children: [
		{
			path: 'set',
			name: 'set',
			meta: {
				title: '通用设置',
				code: '',
				auth:true,
			},
			redirect: '/pub/set/ps',
			component: () => import('@/pages/routerTo/index.vue'),
			children: [
				{
					path: 'ps',
					name: 'ps',
					meta: {
						title: '个人设置',
						code: '',
						show:true,
						auth:true,
					},
					component: () => import('@/pages/person/setting/index.vue'),
				},
				{
				path: 'security',
				name: 'security',
				meta: {
					title: '账号安全',
					code: '',
					show:true,
					auth:true,
				},
				component: () => import('@/pages/person/security/index.vue'),
				},

			]
		},
		{
			path: 'm',
			name: 'm',
			meta: {
				title: '消息通知',
				code: '',
				auth:true,
			},
			// redirect: 'person/message/notice',
			component: () => import('@/pages/routerTo/index.vue'),
			children: [
				{
					path: 'notice',
					name: 'notice',
					meta: {
						title: '系统公告',
						code: '',
						show:true,
						auth:true,
					},
					component: () => import('@/pages/person/message/notice.vue'),
				},
				{
					path: 'msg',
					name: 'msg',
					meta: {
						title: '系统通知',
						code: '',
						show:true,
						auth:true,
					},
					component: () => import('@/pages/person/message/notification.vue'),
				},
			],
		},
			{
				path: 'profile',
				name: 'profile',
				meta: {
					title: '个人资料',
					code: '',
					auth:true,
				},
				component: () => import('@/pages/tagSettings/index.vue'),
			},
			{
				path: 'script',
				name: 'script',
				meta: {
					title: '我的投稿',
					code: '',
					auth: true,
				},
				component: () => import('@/pages/person/myScript/index.vue'),
			}
		]
	},
	{
		path: '/personPage',
		name: "personPage",
		meta: {
			title: "个人主页",
			code:'',
			home:true,
		},
		component: () => import("../pages/personPage/index.vue"),
	},

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
export function isUserLoggedIn() {
	// 这里应该是检查用户是否登录的逻辑，例如检查token或者session
	return localStorage.getItem(Constant.HttpAccessToken) || false;
}
router.beforeEach((to, from, next) => {
	const isMobile =  navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	// 用户服务协议
	if(isMobile && !(['/privacy', '/agreement', '/liuliuNet', '/qrCode'].includes(to.path))) {
		window.location.href = process.env.VUE_APP_MOBILE_HOME_PATH;
	}
	if(to.meta.auth) {
		if(isUserLoggedIn()) {
			if(store.state.user.userPerm.permCodes) {
				if(to.meta.perm) {
					let find = UserPerm.hasPerm(to.meta.code)
					if(find) {
						next()
					}else {
						next('/m/noPerm')
					}
				}else {
					next()
				}

			}else {
				store.dispatch('GetInfo').then(res => {
					if(to.meta.perm) {
						let find = UserPerm.hasPerm(to.meta.code)
						if(find) {
							next()
						}else {
							next('/m/noPerm')
						}
					}else {
						next()
					}
				})
			}
		}else {
			if(to.meta.show){
				next()
			}else{
				next('/')
			}
		}
	}
	else {
		//针对首页的设置需要获取用户信息
		if(to.meta.home) {
			if(isUserLoggedIn()) {
				if(!store.state.user.userPerm.permCodes) {
					store.dispatch('GetInfo').then(res => {})
				}
			}
		}
		next()
	}
})
  // 在main.js中
  /* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError((error) => {
	const pattern = /Loading chunk (\d)+ failed/g
	const isChunkLoadFailed = error.message.match(pattern)
	const targetPath = router.history.pending.fullPath
	if (isChunkLoadFailed) {
		// 用路由的replace方法，
		// 并没有相当于F5刷新页面，
		// 失败的js文件并没有从新请求，
		// 会导致一直尝试replace页面导致死循环，
		// 而用 location.reload 方法，相当于触发F5刷新页面，
		// 虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，
		// 从而曲线救国解决该问题
		window.location.reload()
		// router.replace(targetPath);
	}
})







export default router
