<template>
    <div class="infoBox">
        <searchForm :item-list="searchFormList" @search="search" @reset="reset"/>
        <!-- 新增 -->
        <div class="addButton">
          <div class="checkBox">
              <el-checkbox v-model="checkedFile" label="全选" :value="true" size="large"  @change="allChange"/>
          </div>
          <div class="newBtnText">
              已选择{{checkedList.length}}个模型
          </div>
          <BlockBtn :text="'删除'" :textStyle="{'margin-left':'20px','float':'left','padding':'3px 6px','margin-top':'8px'}" @click="allDelete"/>
          <BlockBtn :text="'上传模型'" :textStyle="{'margin-right':'10px','float':'right',}" 
          @click="addDict(activeName)" v-if="UserPerm.hasPerm(Constant.PermMaterialModelUpload)"/>
        </div>
        <div class="tableBox">
          <div class="contentBox" v-if="dataList">
              <div class="infoItem" v-for="(i,index) in dataList" :key="index">
                  <div class="infoImg">
                      <div class="imgBox">
                          <img :src="OSSURL+i.preview" alt="" v-if="i.isFinish===1 && i.hasError===0">
                      </div>
                      <div class="btnBox">
                          <BlockBtn  :text="'编辑'" :textStyle="{'margin-right':'10px','padding':'5px 11px',}" @click="edit(i.id)"/>
                          <BlockBtn  :text="'删除'" :textStyle="{'padding':'5px 11px',}" @click="deleteBtn(i.id)"/>
                      </div>
                      <div class="textBox" v-if="i.isFinish===0">
                          {{i.stateName}}
                      </div>
                      <div class="textBox" v-if="i.isFinish===1 && i.hasError===1">
                          {{i.reason}}
                      </div>
                  </div>
                  <div v-if="i.groupId === Constant.MaterialPublic" class="infoVersion">
                      {{i.version}} <span v-if="i.originId > 0">({{i.originId}})</span>
                  </div>
                  <div class="infoCheck">
                      <el-checkbox-group v-model="checkedList">
                          <el-checkbox label="" :value="i.id" size="large" />
                      </el-checkbox-group>
                  </div>
                  <div class="infoText">
                    <el-popover
                      placement="bottom-start"
                      :width="200"
                      trigger="hover"
                      :content="i.name"
                    >
                      <template #reference>
                          <div class="modelTitle">
                            {{i.name}}
                          </div>
                      </template>
                    </el-popover>
                  </div>
              </div>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
              <span class="fillSpan"></span>
          </div>
          <div class="empt" v-else>
              <NoData :text="'暂无数据'" 
              :imgStyle="{'width':'150px','height':'150px','margin-top':'30px',}" 
              :textStyle="{'font-size':'22px','margin-top':'30px',}"/>
          </div>
          <div class="pageBox" v-if="total > 0" >
              <page 
                  :current-page="query.page" 
                  :page-size="query.size" 
                  :total-items="total"
                  @current="handleCurrentChange" 
                  @size="handleSizeChange" 
              />
          </div>
        </div>
        <el-dialog
            v-model="dialogVisible"
            title="编辑信息"
            width="500"
            :before-close="handleClose"
            :close-on-click-modal="false"
        >
            <el-form :model="form" label-width="120px" ref="ruleFormRef" :rules="rules">
                <el-tabs v-model="dialogActive" class="demo-tabs"  v-if="materialType===Constant.UserTypePlatform">
                    <el-tab-pane label="基础" :name="1" ></el-tab-pane>
                    <el-tab-pane label="高级" :name="2" ></el-tab-pane>
                </el-tabs>
                
                <div class="infoBox" v-if="dialogActive === 1">
                  <el-form-item label="名称" prop="name">
                      <el-input v-model="form.name"  placeholder="请输入"/>
                  </el-form-item>
                  <el-form-item label="摆放位置" prop="position">
                      <el-select v-model="form.position"  style="width:100%;">
                          <el-option v-for="i in modelPosition" :key="i.key" :label="i.value" :value="i.key"/>
                      </el-select>
                  </el-form-item>
                  <el-form-item label="渲染分类" prop="feature">
                      <el-cascader v-model="form.feature" :options="options1" :props="props" clearable 
                      :show-all-levels="false" @change="casChange1" style="width:100%;"/>
                  </el-form-item>
                  <el-form-item label="素材目录" prop="category"  v-if="materialType===Constant.UserTypePlatform">
                      <el-cascader v-model="form.category" :options="options" :props="props" clearable 
                      :show-all-levels="false" @change="casChange" style="width:100%;"/>
                  </el-form-item>
                  <el-form-item label="素材目录" prop="category"  v-if="materialType===Constant.UserTypeCompany || materialType===Constant.UserTypeSchool">
                      <el-select v-model="form.category"  style="width:100%;" clearable>
                          <el-option v-for="i in options" :key="i.key" :label="i.value" :value="i.key"/>
                      </el-select>
                  </el-form-item>
                </div>
                <div class="infoBox" v-if="dialogActive === 2">
                  <el-form-item label="属性" prop="attr">
                      <el-select v-model="form.attr"  style="width:100%;">
                          <el-option v-for="i in modelAttr" :key="i.key" :label="i.value" :value="i.key"/>
                      </el-select>
                  </el-form-item>
                  <el-form-item label="价格" prop="price">
                      <el-input v-model="form.price"  placeholder="请输入"/>
                  </el-form-item>
                  <div class="formItemBox" v-for="(item,index) in tagList" :key="index">
                    <el-form-item :label="item.name">
                      <el-select v-model="form['tagList'+index]"  style="width:100%;">
                          <el-option v-for="i in item.children" :key="i.id" :label="i.name" :value="i.id"/>
                      </el-select>
                    </el-form-item>
                  </div>
                  <el-form-item label="系统内置" v-if="materialType===Constant.UserTypePlatform">
                      <el-radio-group v-model="form.isSystem">
                          <el-radio :value="1">是</el-radio>
                          <el-radio :value="0">否</el-radio>
                      </el-radio-group>
                  </el-form-item>
                  <el-form-item label="推荐首页" v-if="materialType===Constant.UserTypePlatform">
                      <el-radio-group v-model="form.isHome">
                          <el-radio :value="1">是</el-radio>
                          <el-radio :value="0">否</el-radio>
                      </el-radio-group>
                  </el-form-item>
                  <div class="customTagNames" v-if="materialType===Constant.UserTypePlatform">
                    <div class="customTitle">
                      <div class="nameTitle">别名列表</div>
                      <div class="nameAdd" @click="add">新增别名</div>
                    </div>
                      <div class="TagNamesBox" v-for="(item, index) in TagNamesArr" :key="index">
                          <el-input v-model="item.TagName" placeholder="请输入别名">
                            <template #append>
                              <span class="btnDel" @click="del(index)">删除</span>
                            </template>
                          </el-input>
                      </div>
                  </div>
                </div>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <BlockBtn :text="'取消'" :textStyle="{'margin-right':'10px',}" @click="handleClose"/>
                    <BlockBtn :text="'提交'" :textStyle="{}" @click="onSubmit(ruleFormRef)"/>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import BlockBtn from '@/components/common/blockBtn';
import NoData from '@/components/common/noData';
import api from '@/api/api'
import { ref,reactive,computed } from 'vue'
import Constant from '@/constant/constant';
import UserPerm from '@/constant/userPerm';
import {useRouter} from 'vue-router'
import { OSSURL} from '../../util/file'
import page from '@/components/common/page';
import dataFactory from '@/api/dataFactory';
import messageBox from '@/constant/message';
import searchForm from '@/components/common/search';
import { useStore } from 'vuex'
    const prop = defineProps(['activeName','materialType'])
    const vue = useStore()
    const rules = reactive({
        position: [{ required: true, message: '位置不能为空', trigger: 'change' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
    })
    const props = {
        checkStrictly: true,
        children: 'children',
        label: 'nodeName',
        value:'nodeId',
    }
    const tagList = ref([])
    const dialogVisible = ref(false)
    const TagNamesArr = ref([])
    const form = ref({
      isSystem:0,
      isHome:1,
    })
    const setForm = ref({})
    const ruleFormRef = ref()
    const options = ref([])
    const options1 = ref([])
    const optionsVersion = ref([{'key': '', 'value': '全部'}, {'key': '1.0', 'value': '1.0'}, {'key': '-', 'value':'空版本'}])
    const modelPosition = ref([])
    const modelAttr = ref([])
    const router = useRouter()
    const activeName = ref(prop.activeName)
    const dialogActive = ref(1)
    const materialType = ref(prop.materialType)
    const loading = ref(false)
    const dataList = ref([])
    const query = ref({
        page: Constant.PaginationDefaultPage,
        size: Constant.PaginationDefaultSize,
        group:materialType.value
    })
    const total = ref(0)
    const checkedFile = ref(false)
    const checkedList = ref([])
    const allChange = (i) => {
      if(i) {
        dataList.value.filter(res => {
          checkedList.value.push(res.id)
        })
      }else {
        checkedList.value = []
      }
    }
    const allDelete = () => {
      if(checkedList.value.length <= 0) {
        messageBox.error('请选择模型!'); 
        return
      }
      messageBox.elMessageBox().then(() => {
        api.ModelBatchDel({ids: checkedList.value}).then((resp) => {
          messageBox.success('删除成功!');
          checkedList.value = []
          getDataList()
        }).catch((err) => {
          ;
        });
      }).catch((err) => {
          messageBox.info('已取消删除!');
      });
    }
    //function
    //获取字典值
    dataFactory.DictList([Constant.DictCodeModelPosition,Constant.DictCodeMaterialAttr]).then(res => {
        modelPosition.value = res[Constant.DictCodeModelPosition]
        modelAttr.value = res[Constant.DictCodeMaterialAttr]
    })
    //获取资源
    api.TagList({pid: Number(0)}).then((resp) => {
      const arr = resp?.result || []
      arr.forEach(res => {
        if(res.tagType ===2 ) {
          api.TagList({pid: res.id}).then((resp) => {
            res.children = resp.result
            tagList.value.push(res)
          }).catch((err) => {
            ;
          });
        }
      })
    }).catch((err) => {
      ;
    });
    //获取分类
    const DictFaList = () =>{
      if(materialType.value === Constant.UserTypePlatform) {
        api.TreeView({bizCode:Constant.TreeModel,nodeId:'0',}).then((resp) => {
          options.value = resp.result.children?.filter(res => {
            return res.state === 1
          }).map(item => {
            item.children = item.children?.filter(items => items.state === 1)
            return item
          }) || []
        }).catch((err) => {
            ;
        });
      }else if(materialType.value === Constant.UserTypeCompany || materialType.value === Constant.UserTypeSchool) {
        dataFactory.DirList(2).then((resp) => {
          options.value = resp
        })
      }
    }
    DictFaList()
    //获取特性
      api.TreeView({bizCode:Constant.TreeModelFeature,nodeId:'0',}).then((resp) => {
          if(resp?.result) {
            options1.value = resp.result.children || []
          }
      }).catch((err) => {
          
      });
    
    const searchFormList = computed(() => {
      let arr = [
        {label: '模型名称', prop: 'name', type: 'input'},
      ]
      
      if(materialType.value === Constant.UserTypePlatform) {
        arr.push({label: '素材目录', prop: 'categoryList', type: 'cascader', list: options.value, props:{...props,multiple: true,}})
        arr.push({label: '版本', prop: 'version', type: 'select', list: optionsVersion.value})
      }else if(materialType.value === Constant.UserTypeCompany || materialType.value === Constant.UserTypeSchool) {
        arr.push({label: '素材目录', prop: 'categoryList', type: 'select', list: options.value})
      }
        return arr
    })
    const search = (form) => {
      let arr = []
      if(materialType.value === Constant.UserTypePlatform) {
        arr = form.categoryList?.map(i => {
          return Number(i)
        })

      }else if(materialType.value === Constant.UserTypeCompany || materialType.value === Constant.UserTypeSchool) {
          arr.push(Number(form.categoryList))
      }
      setForm.value = {...form,categoryList:arr}
      getDataList(setForm.value)
    }
    const reset = () => {
        query.value.page = Constant.PaginationDefaultPage
        query.value.size = Constant.PaginationDefaultSize
        setForm.value={}
        getDataList()
    }
    const add = () => {
      TagNamesArr.value.push({
        TagName:'',
      })
    }
    const del = (i) => {
      TagNamesArr.value.splice(i,1)
    }
    const handleCurrentChange = (val) => {
        query.value.page = val
        getDataList()
    }
    const handleSizeChange = (val) => {
        query.value.size = val
        getDataList()
    }
    //模型上传
    const addDict = (activeName) => {
      if(activeName === Constant.TreeModel) {
        router.push({ path: '/p/material/mu', query: { materialType: materialType.value }})
      }
    }
    //获取列表数据
    const getDataList = () => {
        api.ModelList({...query.value,...setForm.value}).then((resp) => {
          dataList.value = resp.result
          total.value = resp.total
        }).catch((err) => {
            ;
        });
    }
    getDataList()
    //模型分类处理结果
    const edit = (id) => {
        api.ModelView({id:id}).then((resp) => {
          if(resp.customTagNames) {
            TagNamesArr.value = []
            resp.customTagNames.filter(res => {
              TagNamesArr.value.push({
                TagName:res
              })
            })
          }else {
            TagNamesArr.value.push({
              TagName:'',
            })
          }
          console.log(resp.category)
          form.value = {
            ...resp,
            category:resp.category===0 ? '' : resp.category+"",
            position:resp.position+"",
            feature:resp.feature+"",
          }
          if(form.value.tagIds?.length>0){
            tagList.value.filter((item,index) => {
              item.children.filter(item1 => {
                if(form.value.tagIds.includes(item1.id)) {
                  form.value["tagList"+index] = item1.id
                }
              })
            })
          }
          
        }).catch((err) => {
            ;
        });
      dialogVisible.value = true
      dialogActive.value = 1
    }
    //删除
    const deleteBtn = (id) => {
        messageBox.elMessageBox().then(() => {
          api.ModelState({id: id, state: Constant.StateDelete}).then((resp) => {
            messageBox.success('删除成功!');
            getDataList()
          }).catch((err) => {
            ;
          });
        }).catch((err) => {
            messageBox.info('已取消删除!');
        });
    }
    //模型分类处理结果
    const casChange = (i) => {
        form.value.category = i.slice(-1)[0]
    }
    const casChange1 = (i) => {
        form.value.feature = i.slice(-1)[0]
    }
    //关闭弹窗
    const handleClose = () => {
      dialogVisible.value = false
      TagNamesArr.value = []
      form.value = {
        isSystem:0,
        isHome:1,
      }
    }
    //确定提交
    const onSubmit = (formEl) => {
      console.log(form.value.category)
      console.log(parseInt(form.value.category))
        let arrTag = []
        for(let key in form.value) {
          if(key.indexOf('tagList') > -1 && form.value[key]) {
            arrTag.push(form.value[key])
          }
        }
        form.value.customTagNames = []
        form.value.tagIds = arrTag
        if(TagNamesArr.value.length>0) {
          TagNamesArr.value.map(res => {
            if(res.TagName) {
              form.value.customTagNames.push(res.TagName)
            }
          })
        }
        if (!formEl) return
        formEl.validate((valid) => {//对表单进行一个校验，接收一个回调函数，返回promise
            if (valid) {
              form.value = {
                ...form.value,
                category:form.value.category?parseInt(form.value.category):0,
                position:parseInt(form.value.position),
                feature:parseInt(form.value.feature),
                price:Number(Number(form.value.price).toFixed(2)),
              }
              api.ModelUpdate({...form.value}).then((resp) => {
                messageBox.success('修改成功!');
                dialogVisible.value = false
                getDataList()
                TagNamesArr.value = []
              }).catch((err) => {
                ;
                form.value.category=form.value.category===0 ? '' : form.value.category+"";
                form.value.position=form.value.position+"";
                form.value.feature=form.value.feature+"";
              });
            } else {
                return false
            }
        })
    }
</script>
<style lang="scss" scoped>
.customTagNames {
  font-family: 'PingFang SC', 'Helvetica', 'Source Han Sans';
  overflow: hidden;
  .TagNamesBox {
    padding-bottom:10px; 
    width:calc(100% - 51px);
    float: right;
  }
  .btnDel {
    color: #f56c6c;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: #f89898;
    }
  }
  .customTitle {
    overflow: hidden;
    padding-bottom: 10px;
    .nameTitle {
      font-size: 14px;
      line-height: 24px;
      color: #606266;
      float: left;
      margin-left: 51px;
    }
    .nameAdd {
      padding: 5px 10px;
      font-size: 12px;
      float: right;
      color: #409eff;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        color: #79bbff;
      }
    }
  }
}
.inline {
    display: inline-flex;
}
.right_content {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  min-height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  .infoBox {
    display: flex;
    flex-direction: column;
  }
  .tableBox {
      width: 100%;
      .empt {
        text-align: center;
        font-size: 20px;
        margin-top: 40px;
      }
      .pageBox {
        display: flex;
        margin:20px 0 ;

      }
      .contentBox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-left: -20px;
        .fillSpan {
          width: 258px;
          height: 0;
          background: #2B2E42;
          box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
          margin-left: 20px;
          position: relative;
        }
        .infoItem {
          width: 258px;
          height: 260px;
          padding-bottom: 20px;
          background: #2B2E42;
          box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
          margin-top: 20px;
          margin-left: 20px;
          position: relative;
          .infoCheck {
            position: absolute;
            top: 0;
            right: 0;
          }
          .infoImg {
            margin: 20px;
            width: 220px;
            height: 206px;
            border-radius: 4px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .imgBox {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }
            .btnBox {
              position: absolute;
              bottom: 10px;
              right: 0;
              padding: 10px 10px 0 0;
              font-size: var(--smallFont);
              flex-direction: row;
              color: #fff;
              display: none;
            }
            .textBox {
              position: absolute;
              text-align: center;
              font-size: 18px;
              flex-direction: row;
              color: red;

            }
          }
          .infoText {
            margin-top: 0;
            margin-left: 19px;
            margin-right: 19px;
            overflow: hidden;
            .modelTitle {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-weight: 700;
              opacity: .8;
              margin-bottom: 10px;
            }
          }
          
          &:hover {
            color: var(--red);
            .infoImg {
              background: rgba(137, 207, 240,.2);
            }
            .btnBox {
              display: flex;
            }
          }
        }
      }
  }
  .header {
    width: 100%;
  }
  .addButton {
    width: 100%;
    .checkBox{
      float: left;
    }
    .newBtnText {
        float: left;
        margin-left: 20px;
        line-height: 40px;
    }
  }
  .infoVersion {
      font-weight: 800;
      position: absolute;
      top: 5px;
      left: 10px;
  }
}
</style>
